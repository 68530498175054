<template>
  <div id="layers">
    <div id="failed" v-if="failedConversions.length > 0">
      <h3 class="mt-10 ml-4">{{this.$t('failedConversions')}}</h3>
      <vs-table ref="failed_conversions_table" pagination :max-items="5" :data="this.failedConversions">
        <template slot="thead">
          <vs-th sort-key="portal">{{$t('portal')}}</vs-th>
          <vs-th sort-key="conversion_start_date">{{$t('conversion_start_date')}}</vs-th>
          <vs-th sort-key="data_title">{{$t('data_title')}}</vs-th>
          <vs-th sort-key="data_type">{{$t('data_type')}}</vs-th>
          <vs-th sort-key="user_email">{{$t('user_email')}}</vs-th>
        </template>
        <template slot-scope="{data}">
          <tbody>
            <template v-for="(tr, indextr) in data">
              <vs-tr :data="tr" :key="indextr" v-if="tr.conversion_status==='error'">

                <vs-td class="inline">
                  <p class="portal">{{ $t(tr.portal) }}</p>
                </vs-td>

                <vs-td>
                  <p>{{ tr.conversion_start_date | longDate }}</p>
                </vs-td>

                <vs-td>
                  <p class="layer_name">{{ decodeURIComponent(tr.data_title) }}</p>
                </vs-td>

                <vs-td>
                  <p class="layer_type">{{ $t(tr.data_type) | title }}</p>
                </vs-td>
                
                <vs-td class="inline">
                  <p class="user_email">{{ $t(tr.user_email) }}</p>
                </vs-td>

              </vs-tr>
            </template>
          </tbody>
        </template>
      </vs-table>
    </div>

    <vs-progress class="w-full" v-if="!loaded" indeterminate color="primary"></vs-progress>
    <div v-else>
      <h3 class="mt-10 ml-4">{{this.$t('finishedConversions')}}</h3>
      <vs-table :data="layers" pagination :max-items="itemsPerPage" search>
        <template slot="thead">
          <vs-th sort-key="layer_id">{{ $t('layer_id') }}</vs-th>
          <vs-th sort-key="layer_type">{{ $t('layer_type') }}</vs-th>
          <!-- <vs-th sort-key="layer_key">{{ $t('layer_key') }}</vs-th> -->
          <vs-th sort-key="layer_name">{{ $t('layer_name') }}</vs-th>
          <vs-th sort-key="layer_size">{{ $t('layer_size') }}</vs-th>
          <vs-th sort-key="conversion_end_date">{{ $t('conversion_end_date') }}</vs-th>
          <vs-th sort-key="projects_count">{{ $t('projects_count') }}</vs-th>
          <vs-th sort-key="projects">{{ $t('projects') }}</vs-th>
          <vs-th sort-key="portal">{{ $t('portal') }}</vs-th>
          <vs-th>{{ $t('actions') }}</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data" :state="layerStatusColor(data[indextr])">
            <!-- layer_id -->
            <vs-td :data="data[indextr].layer_id">
              <span>#{{data[indextr].layer_id}}</span>
            </vs-td>
            <!-- layer_type -->
            <vs-td :data="data[indextr].layer_type">
              <span>{{data[indextr].layer_type}}</span>
            </vs-td>
            <!-- layer_key -->
            <!-- <vs-td :data="data[indextr].layer_key">
              <span>{{data[indextr].layer_key}}</span>
            </vs-td> -->
            <!-- layer_name -->
            <vs-td :data="data[indextr].layer_name">
              <span>
                <a :href="`https://modelya.rimnat.com/?portal_id=${data[indextr].portal_id}&layer_key=${data[indextr].layer_key}`" target="_blank" rel="nofollow">
                  {{ decodeURIComponent(data[indextr].layer_name) }}
                </a>
              </span>
            </vs-td>
            <!-- layers_total_size -->
            <vs-td :data="data[indextr].layer_size">
              <span>{{ parseFloat(data[indextr].layer_size) | prettyBytes }}</span>
            </vs-td>
            <!-- conversion_end_date -->
            <vs-td :data="data[indextr].conversion_end_date">
              <span>{{ data[indextr].conversion_end_date | middleDate }}</span>
            </vs-td>
            <!-- projects_count -->
            <vs-td :data="data[indextr].projects_count">
              <span>{{ data[indextr].projects_count }}</span>
            </vs-td>
            <!-- projects -->
            <vs-td :data="data[indextr].projects">
              <span>{{ data[indextr].projects }}</span>
            </vs-td>
            <!-- portal -->
            <vs-td :data="data[indextr].portal">
              <span>{{ data[indextr].portal }}</span>
            </vs-td>
            <!-- actions -->
            <vs-td :data="data[indextr].layer_name">
              <a :href="`/admin/layer/${data[indextr].layer_key}`" target="_blank" rel="nofollow">{{ $t("edit") }}</a>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
  </div>
</template>
<script>
import axios from '@/axios.js'
import vSelect from 'vue-select'
import moment from 'moment'
import VueApexCharts from 'vue-apexcharts'
import apexChatData from 'apexcharts'

import Datepicker from 'vuejs-datepicker'

export default {
  components: {
    moment,
    VueApexCharts, 
    vSelect, 
    Datepicker
  },
  data () {
    return {
      apexChatData,
      api_server_baseurl: this.$appConfig.apiBaseUrl,
      appConfig: this.$appConfig,
      layers: {},
      failedConversions: {},
      itemsPerPage: 50,
      loaded: false
    }
  },
  methods: {   
    refreshAll () {
      this.getLayersInfos()
      this.getFailed()
    },
    getFailed () {
      const params = ''
      const rimnat_api_call_url = `${this.api_server_baseurl}/admin/layer/getFailedConversions?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          const failedConversions = response.data
          for (let i = 0; i < failedConversions.length; i++) {
            // convert string to int to allow sort in table
            failedConversions[i].layer_size = parseInt(failedConversions[i].layer_size)
            failedConversions[i].projects_count = parseInt(failedConversions[i].projects_count)
            if (failedConversions[i].conversion_end_date && failedConversions[i].conversion_end_date.length >= 10) failedConversions[i].conversion_end_date = parseInt(failedConversions[i].conversion_end_date.substring(0, 10).replaceAll('-', ''))
          }
          this.failedConversions = failedConversions
          this.loaded = true
        })
        .catch((error)   => { console.log(error) })
    },
    getLayersInfos () {
      const params = ''
      const rimnat_api_call_url = `${this.api_server_baseurl}/admin/layer/getLayersSynthesis?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          const layers = response.data
          for (let i = 0; i < layers.length; i++) {
            // convert string to int to allow sort in table
            layers[i].layer_size = parseInt(layers[i].layer_size)
            layers[i].projects_count = parseInt(layers[i].projects_count)
            if (layers[i].conversion_end_date && layers[i].conversion_end_date.length >= 10) layers[i].conversion_end_date = parseInt(layers[i].conversion_end_date.substring(0, 10).replaceAll('-', ''))
          }
          this.layers = layers
          this.loaded = true
        })
        .catch((error)   => { console.log(error) })
    },
    getSizeColor (num) {
      if (num > 80)  return 'danger'
      if (num > 60)  return 'warning'
      if (num >= 40) return 'primary'
      if (num < 40)  return 'success'
      return 'primary'
    },
    layerStatusColor (layer) {
      let color = ''
      if (layer.projects_count == 0) color = 'danger'
      if (layer.projects == '') color = 'danger'
      return color
    }
  },
 
  computed: {

  },

  beforeMount () {
  },

  mounted () {
    document.body.classList.add('theme-dark')
    this.refreshAll()
  },

  watch: {
    scale () {
      this.refreshAll()
    },
    start () {
      this.refreshAll()
    },
    end () {
      this.refreshAll()
    }
  }
}

</script>
<style lang="scss">

.theme-dark input {
    background: none !important;
}
</style>
